import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationAdd() {
  // Use toast
  const toast = useToast()

  const formBuilderFieldTypes = {
    STEP: 'step',
    PLAIN_TEXT: 'plain_text',
    SHORT_TEXT: 'short_text',
    LONG_TEXT: 'long_text',
    EMAIL: 'email',
    NUMBER: 'number',
    PHONE: 'phone',
    DATE: 'date',
    DROPDOWN: 'dropdown',
    MULTI_SELECT: 'multi_select',
    CHECKBOX: 'checkbox',
    RADIO_BUTTON: 'radio_button',
    FILE_UPLOAD: 'file_upload',
  }

  const postCamperApplication = queryParams => axios
    .post('/apply-camper', queryParams)
    .then(response => {
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'New Camper Application was successfully created',
      //     icon: 'ThumbsUpIcon',
      //     variant: 'info',
      //   },
      // })
      return response.data.application_id
    })
    .catch(
    //   () => toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Error posting Camper Application',
    //     icon: 'AlertTriangleIcon',
    //     variant: 'danger',
    //   },
    // })
    )

  const saveProgress = queryParams => axios
    .post('/applications/save-progress', queryParams)
    .then(response => {
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: queryParams.is_draft ? 'Your step was successfully saved' : 'Your step was successfully sent',
      //     icon: 'ThumbsUpIcon',
      //     variant: 'info',
      //   },
      // })
      return response
    })
    .catch(error => {
      toast({
      component: ToastificationContent,
      props: {
        title:  queryParams.is_draft ? 'Error saving step' : 'Error sending step',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
      const { response } = error
      return response
    })


  const fetchUserAttributes = queryParams => axios
    .get('/user-attributes/', { params: queryParams })
    .then(response => response.data.data)
    .catch()

  const postStaffApplication = queryParams => axios
    .post('/apply-staff', queryParams)
    .then(response => {
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'New Staff Application was successfully created',
      //     icon: 'ThumbsUpIcon',
      //     variant: 'info',
      //   },
      // })
      return response.data.application_id
    })
    .catch(
    //   () => toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Error posting Staff Application',
    //     icon: 'AlertTriangleIcon',
    //     variant: 'danger',
    //   },
    // })
    )

  const postAuthorizeCreditCard = queryParams => axios
    .post('/applies/pay', queryParams)
    .then(response => {
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Credit Card was successfully authorized',
      //     icon: 'ThumbsUpIcon',
      //     variant: 'info',
      //   },
      // })
      return response.data.data
    })
    .catch(() => {
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Error authorising Credit Card',
      //     icon: 'AlertTriangleIcon',
      //     variant: 'danger',
      //   }
      // })
      return 'error'
    })

  const postSessionsInApplication = (ID, queryParams) => axios
    .post(`/applications/${ID}/sessions`, queryParams)
    .then(() => {
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Session was successfully added',
      //     icon: 'ThumbsUpIcon',
      //     variant: 'info',
      //   },
      // })
    })
    .catch(
    //   () => toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Error add Sessions',
    //     icon: 'AlertTriangleIcon',
    //     variant: 'danger',
    //   },
    // })
    )

  const postDivisionInApplication = queryParams => axios
    .post(`/applications/divisions`, queryParams)
    .then(response => response.data.data)
    .catch(
    //   () => toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Error get Division',
    //     icon: 'AlertTriangleIcon',
    //     variant: 'danger',
    //   },
    // })
    )

  const postApplicationPayCondition = queryParams => axios
    .post(`/application-pay-conditions`, queryParams)
    .then(response => response.data.data)
    .catch(
    //   () => toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Error add Payment Plan',
    //     icon: 'AlertTriangleIcon',
    //     variant: 'danger',
    //   },
    // })
    )

  const postPaymentPlanChargesInApplication = queryParams => axios
    .post(`/application-payment-plans/charges`, queryParams)
    .then(response => response.data.data)

  const fetchProgram = ID => axios
    .get(`/programs/${ID}`)
    .then(response => response.data.data)
    .catch(
    //   () => toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Error fetching program item',
    //     icon: 'AlertTriangleIcon',
    //     variant: 'danger',
    //   },
    // })
    )

  const fetchForm = ID => axios
    .get(`/forms/${ID}`)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Error fetching form',
      //     icon: 'AlertTriangleIcon',
      //     variant: 'danger',
      //   },
      // })
      return response.status
    })

  const uploadFiles = (ID, file) => axios
    .post(`/file/upload/${ID}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => response.data)
    .catch(error => {
      const { response } = error
      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Error uploading file',
      //     icon: 'AlertTriangleIcon',
      //     variant: 'danger',
      //   },
      // })
      return response.status
    })

  const removeFile = queryParams => axios
    .post('/file/remove', queryParams)
    .then(response => response.data)
    .catch()

  const fetchApplicationGroupOptions = queryParams => axios
    .post(`/applications/groups`, queryParams )
    .then(response => response.data.data)
    .catch()

  const fetchApplication = ID => axios
    .get(`/applications/${ID}`)
    .then(response => response.data.data)
    .catch()

  return {
    fetchApplication,
    saveProgress,
    fetchUserAttributes,
    postCamperApplication,
    postStaffApplication,
    postAuthorizeCreditCard,
    fetchProgram,
    fetchForm,
    uploadFiles,
    removeFile,
    formBuilderFieldTypes,
    postSessionsInApplication,
    postDivisionInApplication,
    postApplicationPayCondition,
    fetchApplicationGroupOptions,
    postPaymentPlanChargesInApplication,
  }
}
